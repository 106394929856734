import React, { Component } from "react";
import {
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import { GetData } from "../api/service";
import CommitteeDescription from "../component/Committee/CommitteeDescription";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";

// // import FacilityDescription from "./FacilityDescription";

// const list = [
//     {
//         title: "Library",
//         link: "/facility/library",
//     },
//     {
//         title: "Boys Hostel",
//         link: "/facility/boyshostel",
//     },
//     {
//         title: "Girls Hostel",
//         link: "/facility/girlshostel",
//     },
//     {
//         title: "CIF",
//         link: "/facility/cif",
//     },
//     {
//         title: "Canteen",
//         link: "/facility/canteen",
//     },
//     {
//         title: "ICGC",
//         link: "/facility/icgc",
//     },
// ];

class CommitteesContainer extends Component {
    state = {
        link: "",
        data: [],
        isLoaded: false,
        com: {},
        child: false
    };

    componentDidMount() {
        let link = this.props.match.params.link;
        this.setState({
            link: link,
        });
        GetData(`/getcommittee`)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.link != this.props.match.params.link) {
            let link = this.props.match.params.link;
            this.setState({
                link: link,
            });
        }
    }

    setCom(d) {

        this.setState({
            child: false
        }, () => {
            this.setState({
                com: d,
                child: true
            })
        })
        //   console.log(this.state);
    }

    render() {
        return (
            <div>
                <BreadCrumbOwn title="Home > Committees" />
                <Container className="mb-4">
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={3}
                            style={{ paddingTop: "1em" }}
                        >
                            <Typography variant="h6" style={{ margin: "1em 0 0 0" }} gutterBottom>
                                Committees
                            </Typography>
                            <Card
                                style={{
                                    boxShadow: "none",
                                    position: "stickey !important",
                                    top: "0 !important",
                                }}
                            >
                                <div
                                    style={{
                                        boxShadow: "none",
                                        position: "stickey",
                                        top: "0",
                                    }}
                                >
                                    <List component="nav">
                                        {this.state.data.map((el, index) => (
                                            <ListItem
                                                button
                                                style={{
                                                    border: "1px solid #0002",
                                                    marginBottom: "2px",
                                                    backgroundColor: el === this.state.com ? '#F5EB3D' : '#f9f9f9'
                                                }}
                                                key={index}
                                                onClick={() => this.setCom(el)}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: "1.2em",
                                                        fontWeight: "700",
                                                        padding: "0",
                                                    }}
                                                >
                                                    {el.name}
                                                </div>
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={9} lg={9} style={{minHeight: 800}}>
                            
                            <small><br /> (Click on the name of the committee on the left side To view details)</small>

                            <br />
                            <br />
                            {this.state.child && <CommitteeDescription data={this.state.com} />}
                        </Grid>

                    </Grid>
                </Container>
            </div>
        );
    }
}

export default CommitteesContainer;