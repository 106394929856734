import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';

class AlumniDescription extends Component {
    render() {
        return (
            <div>
                {this.props.type == 'association' ? (<SiteMap1 />) : null}
                {this.props.type == 'committee' ? (<SiteMap />) : null}
                {this.props.type == 'notable' ? (<LandProperty />) : null}
                {this.props.type == 'support' ? (<Building />) : null}
                {this.props.type == 'list' ? (<Building12 />) : null}
                
            </div>
        );
    }
}

export default AlumniDescription;

export const SiteMap1 = (props) => {
    return (
        <div className="mb-4">
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Association
            </Typography>
            </div>
   
    )
}

export const SiteMap = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Committee
            </Typography>

            

        </div>
    )
}

export const LandProperty = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                Notable Alumni
            </Typography>

            
        </div>
    )
}

export const Building = (props) => {
    return (
        <div className='mb-4'>
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                Alumni Support
            </Typography>

            
        </div>
    )
}

export const Building12 = (props) => {

  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
    setData(resp)
    setIsLoaded(true)
      
    })
  }, [])
  return (
      <div className='mb-4'>
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni List
          </Typography>


          {isLoaded && <div>
            
            <ul>
              {Array.isArray(data) && data.map((el,index) => <li key={index}>
                  <a style={{fontSize: 14}} targte="_blank" href={`/department/${el.dept_code}/alumni`}>Alumni list of {el.dept_name}</a>
                </li>)}
              </ul>
            </div>}

          
      </div>
  )
}
