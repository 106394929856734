import React, { Component } from 'react'

import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import { Card, CardHeader } from 'reactstrap';
import BreadCumb from '../Department/BreadCumb';
import { PostData } from '../../api/service';



// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]


export default class PrincipalContainer extends Component {

    state = {
        facultyData: [],
        isLoaded: false,
        dept_code: 'OFF'
    }

    componentDidMount() {


        let d = {
            dept_code: 'OFF',
            type: 'PRINCIPAL'
        }

        PostData(`/getemployeebytype`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    ...resp[0],
                    facultyData: resp,
                    isLoaded: true
                })
            })
    }
    render() {
        return (
            <div>



                <MDBContainer style={{ paddingTop: '20px' }}>

                    <br />

                    {this.state.isLoaded && this.state.facultyData.map((el, index) => {
                        return <div>
                            {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}

                            <div>
                                <MDBRow className='mt-2'>

                                    <MDBCol lg='12' sm="12" xs="12" md="12">
                                        <div className="new-principal-div">
                                            <h5 style={{ textAlign: 'center', color: '#1E3760', fontSize: '1.5em', marginTop: '1vh', fontWeight: 700 }}>Principal's Message</h5>
                                            <p style={{ textAlign: 'justify', marginTop: '3vh', fontWeight: 500 }}><i>Ever since taking over charge as the Principal of the College in May 2020, it has been my topmost priority to further improve the educational atmosphere of the college keeping in mind the changing needs and requirements of the time. Simultaneously, I have been laying stress on the extra-curricular activities of the students and teachers of the college. I aspire to make this institution one of the premier educational institutions of the state in general and Karbi Anglong district in particular. Alongside, I attempt to develop the infrastructure of the college which I feel is an urgent need of the time. I know this will be a Herculean task but I hope to carry out this mission in an orderly and organized manner with the active support of the teachers, office staff, students, guardians and other leading personalities of the area. In this connection, I would like to mention that the constructive suggestions and active help that I received so far from different quarters have been extremely encouraging and I hope to receive the same amount of cooperation from all the concerned in the days to come also. Your persistent help and cooperation are important to secure the objectives of the college within a specific time period</i></p>
                                            <br />
                                            <div>
                                                <div style={{ textAlign: 'left' }}>
                                                    <img
                                                        alt=""
                                                        src={'/images/principaltnc.jpeg'}
                                                        style={{
                                                            width: '200px',
                                                            boxShadow: '1px 2px 10px rgba(0,0,0,0.1)',
                                                            border: `solid 5px wheat`
                                                        }}
                                                    />
                                                </div>
                                               
                                                <h5 style={{ fontSize: '16px', fontWeight: 700 }}>{el.name}</h5>
                                                <h6 style={{ fontSize: '12px' }}><a href={`mailto:${el.email}`}>{el.email}</a></h6>
                                                <h6 style={{ fontSize: '12px' }}><a href={`tel:${el.phone}`}>{el.phone}</a></h6>
                                                <h6 style={{ fontSize: '12px' }}><a href={`tel:${el.mobile}`}>{el.mobile}</a></h6>
                                               

                                                {this.state.isLoaded && this.state.id != undefined && el.liveNow != 0 && <a style={{ color: '#1892FF', cursor: 'pointer', fontWeight: 700 }}
                                                    href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}>Visit Profile</a>}
                                            </div>

                                        </div>
                                    </MDBCol>



                                   
                                </MDBRow>

                            </div>
                        </div>

                    })}






                </MDBContainer>

            </div>
        )
    }
}