import React from 'react'
import {Button} from 'antd'
export default function AnkuranLaunch() {
  return (
    <div style={{
        backgroundImage: `url('https://tnc.ac.in/images/TNC.png')`,
        height: '100vh',
        width: '100vw',
        objectFit: 'contain',
        position: "relative"

    }}>
        

        <Button target="_blank" href={`https://ankuran.tnc.ac.in`} size="large" type="primary" style={{position: "absolute", top: "10%", left: "43.5%"}}>
            Click here to Launch Website
        </Button>
    </div>
  )
}
