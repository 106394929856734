import React, { Component } from 'react';
import { GetData } from '../../api/service';
import { IconButton, Typography } from '@material-ui/core'

import { Drawer, Menu } from 'antd';
import { MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
const { SubMenu } = Menu;




const data = [
  {
    title: 'HOME',
    path: '/',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'ABOUT US',
    path: '/aboutus/about',
    hasSubnav: true,
    subnav: [
      {
        title: 'About',
        path: '/aboutus/about',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'History',
        path: '/aboutus/history',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Vision & Mission',
        path: '/aboutus/vision',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Aims & Objectives',
        path: '/aboutus/icv',
        hasSubnav: false,
        subnav: []
      },
    ]
  },
  {
    title: 'NOTICES',
    path: '/notifications/administrative',
    hasSubnav: true,
    subnav: [
      {
        title: 'Administrative Notices',
        path: '/notifications/administrative',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Academic Notices',
        path: '/notifications/academic',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Recent Achievements',
        path: '/notifications/achievements',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Student Achievements',
        path: '/notifications/sachievements',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Employee Achievements',
        path: '/notifications/eachievements',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Downloads',
        path: '/notifications/downloads',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Tender Notices',
        path: '/notifications/tender',
        hasSubnav: false,
        subnav: []
      },
    ]
  },
  {
    title: 'EVENTS',
    path: '/events',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'ADMINISTRATIONS',
    path: '/administrations/principal',
    hasSubnav: true,
    subnav: [
      {
        title: 'From the Desk of Principal',
        path: '/administrations/principal',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Governing Body',
        path: '/administrations/gb',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Office Staff',
        path: '/administrations/officestaff',
        hasSubnav: false,
        subnav: []
      },]
  },
  {
    title: 'DEPARTMENTS',
    path: '/departments',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'STUDENTS',
    path: '/students/notification',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'FACILITIES',
    path: '/facility/library',
    hasSubnav: true,
    subnav: [
      {
        title: 'Library',
        path: '/facility/library',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Students Support',
        path: '/facility/studentsupport',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'CIF',
        path: '/facility/cif',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Canteen',
        path: '/facility/canteen',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'CGPC',
        path: '/facility/cgpc',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Career Guidance and Placement Cell',
        path: '/facility/Career_Placement_Cell',
        hasSubnav: false,
        subnav: []
      },
    ]
  },
  {
    title: 'IQAC',
    path: '/iqac/strategies',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'RESEARCH',
    path: '/research',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'NIRF',
    path: '/nirf',
    hasSubnav: false,
    subnav: []
  },

  {
    title: 'RTI',
    path: '/rti/home',
    hasSubnav: false,
    subnav: []
  },
  {
    title: 'ALUMNI',
    path: '/alumni/committee',
    hasSubnav: true,
    subnav: [
      {
        title: 'Alumni Association',
        path: '/alumni/association',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Alumni Committee',
        path: '/alumni/committee',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Alumni Support',
        path: '/alumni/support',
        hasSubnav: false,
        subnav: []
      },
      {
        title: 'Alumni List',
        path: '/alumni/list',
        hasSubnav: false,
        subnav: []
      },
     
    ]
  },
  {
    title: 'CONTACT US',
    path: '/contactus',
    hasSubnav: false,
    subnav: []
  }
]

const pos = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1000,
  justifyContent: 'end',
}

class MultiNavBar extends Component {
  state = {
    departments: [],
    isLoaded: false,
    data: data,
    current: '/',
    openMenu: false
  }

  componentDidMount() {
    // GetData(`/getdepartments`)
    //   .then((resp) => {
    //     //console.log(resp)

    //     // arrange them 

    //     let arts = resp.filter(el => el.stream == 'ARTS');
    //     let science = resp.filter(el => el.stream == 'SCIENCE');


    //     let arts_arr = [];
    //     arts.map((el, index) =>
    //       arts_arr.push({
    //         title: el.dept_name,
    //         path: `/department/${el.dept_code}`,
    //         hasSubnav: false,
    //         subnav: []
    //       })
    //     )

    //     let science_arr = [];
    //     science.map((el, index) =>
    //       science_arr.push({
    //         title: el.dept_name,
    //         path: `/department/${el.dept_code}`,
    //         hasSubnav: false,
    //         subnav: []
    //       })
    //     )



    //     let arts_d = {
    //       title: 'Arts Departments',
    //       path: '',
    //       hasSubnav: true,
    //       subnav: arts_arr
    //     }

    //     let science_d = {
    //       title: 'Science Departments',
    //       path: '',
    //       hasSubnav: true,
    //       subnav: science_arr
    //     }


    //     let d = [arts_d, science_d];

    //     let data = this.state.data;
    //     data[4].subnav = d;

    //     // for people

    //     let teaching_people = resp.map((el, index) => {
    //       return ({
    //         title: el.dept_name,
    //         path: `/department/${el.dept_code}/faculty`,
    //         hasSubnav: false,
    //         subnav: []
    //       })
    //     }
    //     );

    //     //data[5]['subnav'][1]['subnav'] = teaching_people



    //     //console.log(data)
    //     this.setState({
    //       data: data,
    //       isLoaded: true,
    //       current: this.props.history.location.pathname
    //     }, () => {
    //       // console.log(this.state)
    //     })

    //   })
  }


  closeMenu = () => {
    this.setState({
      openMenu: false
    })
  }



  handleClick = (e) => {
    this.props.history.push(e.key);
    this.setState({
      current: e.key
    }, () => {
      this.closeMenu()
    })
  }

  render() {
    return (
      <div>
        <div className='desk-menu'>
          <div
            style={this.props.sticky ? {
              ...pos
            } : null}
            className={`navStyle-new`}
          >

            <Menu onClick={this.handleClick} style={{ background: '#F5EB3D', border: 'none', width: '100%', display: 'flex', justifyContent: 'center' }} selectedKeys={[this.state.current]}
              overflowedIndicator={<MenuUnfoldOutlined />}
              mode="horizontal">
              {this.state.data.map((el, index) => {

                if (el.hasSubnav) {
                  return <SubMenu title={el.title} className='navItemPandu'>
                    {el.subnav.map((el1, index1) => {
                      if (el1.hasSubnav) {

                        return <SubMenu title={el1.title}>
                          {el1.subnav.map((el2, index2) =>
                            <Menu.Item className='navItemPandu' key={el2.path}>{el2.title}</Menu.Item>
                          )}
                        </SubMenu>


                      } else {
                        return <Menu.Item className='navItemPandu' key={el1.path}>{el1.title}</Menu.Item>
                      }

                    })}



                  </SubMenu>
                }
                else {
                  return <Menu.Item key="mail" key={el.path} className='navItemPandu'>
                    {el.title}
                  </Menu.Item>
                }
              }
              )}
            </Menu>
          </div>





        </div>

        <div className='mobile-menu-div'>
          <IconButton onClick={() => this.setState({openMenu: true})}>
            <MenuOutlined style={{fontWeight: 700}} />
          </IconButton>
        </div>

        <Drawer title="" placement="right" onClose={() => this.closeMenu()} open={this.state.openMenu}>
          <Menu onClick={this.handleClick} selectedKeys={[this.state.current]}
            overflowedIndicator={<MenuUnfoldOutlined />}
            mode="inline">
            {this.state.data.map((el, index) => {

              if (el.hasSubnav) {
                return <SubMenu title={el.title} >
                  {el.subnav.map((el1, index1) => {
                    if (el1.hasSubnav) {

                      return <SubMenu title={el1.title}>
                        {el1.subnav.map((el2, index2) =>
                          <Menu.Item key={el2.path}>{el2.title}</Menu.Item>
                        )}
                      </SubMenu>


                    } else {
                      return <Menu.Item key={el1.path}>{el1.title}</Menu.Item>
                    }

                  })}



                </SubMenu>
              }
              else {
                return <Menu.Item key="mail" key={el.path} >
                  {el.title}
                </Menu.Item>
              }
            }
            )}
          </Menu>
        </Drawer>

      </div>
    );
  }
}

export default MultiNavBar;

// class OneNavBar extends Component {
//   state = {
//     hover: false
//   }

//   onMouseEnter = () => {
//     this.setState({
//       hover: true
//     })
//   }

//   onMouseLeave = () => {
//     this.setState({
//       hover: false
//     })
//   }

//   render() {

//     return (
//       <div
//         style={{
//           padding: '10px 10px 10px 10px',
//           cursor: 'pointer',
//           position: 'relative',
//           color: this.props.color

//         }}

//         onMouseEnter={this.onMouseEnter}
//         onMouseLeave={this.onMouseLeave}
//         onMouseOver={this.onMouseEnter}
//         onClick={() => !this.props.hasSubnav ? this.props.history.push(this.props.path) : null}
//       >

//         <Typography
//           variant="subtitle2"
//         >
//           {this.props.title}
//         </Typography>


//         {this.props.hasSubnav && this.state.hover ? (<div
//           style={{
//             backgroundColor: this.props.backgroundColor,
//             position: 'absolute',
//             minWidth: this.props.width,
//             zIndex: 999,
//             display: 'grid',
//             gridTemplateRows: '1fr',
//             boxShadow: '1px 1px 3px rgba(0,0,0,0.3)'
//           }}
//         >

//           {this.props.subnav.map((el, index) =>
//             <OneSideNav
//               key={index}
//               {...el}
//               width={this.props.width}
//               color={this.props.color}
//               backgroundOfNav={this.props.backgroundOfNav}
//               backgroundColor={this.props.backgroundColor}
//               textColor={this.props.textColor}
//               history={this.props.history}
//             />
//           )}

//         </div>) : null}
//       </div>
//     )
//   }
// }


// class OneSideNav extends Component {
//   state = {
//     hover: false
//   }
//   onMouseEnter = () => {
//     this.setState({
//       hover: true
//     })
//   }

//   onMouseLeave = () => {
//     this.setState({
//       hover: false
//     })
//   }
//   render() {
//     return (
//       <div
//         style={{
//           color: this.props.textColor,
//           backgroundColor: this.props.backgroundColor,
//           position: 'relative',
//           minWidth: this.props.width,
//           padding: '10px',
//           borderBottom: 'solid thin lightgrey',
//           borderRight: 'solid thin lightgrey',
//           boxShadow: '1px 2px 3px rgba(0,0,0,0.3)',

//         }}
//         onClick={() => !this.props.hasSubnav ? this.props.history.push(this.props.path) : null}

//         onMouseEnter={this.onMouseEnter}
//         onMouseLeave={this.onMouseLeave}
//         onMouseOver={this.onMouseEnter}
//       >
//         <font style={{ fontSize: '0.8em' }}>{this.props.title}</font>  {this.props.hasSubnav ? (<span
//           className="fa fa-angle-right"
//           style={{
//             fontSize: '0.6em',
//             float: 'right',
//             marginTop: '8px'

//           }}></span>) : null}

//         {this.props.hasSubnav && this.state.hover ? (
//           <div
//             style={{
//               position: "absolute",
//               zIndex: 999,
//               left: 0,
//               marginLeft: this.props.width,
//               top: 0,
//               backgroundColor: this.props.backgroundColor
//             }}
//           >
//             {this.props.subnav.map((el, index) =>
//               <OneSideNav
//                 key={index}
//                 {...el}
//                 width={this.props.width}
//                 color={this.props.color}
//                 backgroundOfNav={this.props.backgroundOfNav}
//                 backgroundColor={this.props.backgroundColor}
//                 history={this.props.history}
//               />
//             )}
//           </div>
//         ) : null}

//       </div>
//     )
//   }
// }