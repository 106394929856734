import { LinkOutlined } from '@ant-design/icons'
import { Grid } from '@material-ui/core'
import React from 'react'
import { PostData, SERVER_UPLOAD } from '../../api/service'
import { getQuarterList } from '../../Helper/helperFunctions'
import TitleStyle from '../TitleStyle'





export default function ResearchDescription({
 type,
 dept
}) {
  let names = []



  // we will fetch data for 7, 8, 10, 11, 15
  // link from the faculty profile to departmental profile

  const [research, setResearch] = React.useState([]) // 7 & 8
  const [reasearch_paper, setReasearchPaper] = React.useState([]) // 10
  const [text_book, setTextBook] = React.useState([]) // 11
  const [awards, setAwards] = React.useState([]) // 15
  const [workshop, setWorkshop] = React.useState([]) // 14
  const [otherPublications, setOtherPublications] = React.useState([]) // 14
  const [popularBook, setPopularBook] = React.useState([]) // 14
  const [new_paper, setNewsPaper] = React.useState([]) // 14
  const [eContent, setEContent] = React.useState([]) // 14
  const [magazine, setMagazine] = React.useState([]) // 14



  const [fData, setFData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)


  const [deptName, setDeptName] = React.useState("")
  const [deptImage, setDetpImage] = React.useState([])


  function loadData(){
    setIsLoaded(false)
    PostData(`/employeeprofilewithdeptcode`, {
      dept_code: type
    }).then((resp) => {
    
    setFData(resp)
      setIsLoaded(true)
    })
  }


  React.useEffect(() => {
    
    loadData()

    let ff = dept.find(el => el.dept_code === type)
    if(ff){
    
    setDeptName(ff.dept_name)
    setDetpImage(ff.banner)
      
    }
  },[type, dept])
  

  React.useEffect(() => {
    if (Array.isArray(fData) && isLoaded) {
      // we will saggrigate the data
      let rr1 = []
      let rr2 = []
      let rr3 = []
      let rr4 = []
      let rr5 = []
      let rr6 = []
      let rr7 = []
      let rr8 = []

      fData.map((el) => {
        let r = {
          title: 'Ongoing/Completed Research projects by faculties',
          name: el.name,
          designation: el.designation,
          data: el.research,
        }

        rr1.push(r) // reasearch

        let r2 = {
          title: 'Research Publications by Faculties',
          name: el.name,
          designation: el.designation,
          data: el.research_paper,
        }

        rr2.push(r2) // reasearch publications

        let r3 = {
          title: 'Chapter in Books by Faculties',
          name: el.name,
          designation: el.designation,
          data: el.text_book,
        }

        rr3.push(r3) // reasearch publications


        let r4 = {
          title: 'Other Publications',
          name: el.name,
          designation: el.designation,
          data: el.other_publication,
        }

        rr4.push(r4) // reasearch publications


        let r5 = {
          title: 'Magazine',
          name: el.name,
          designation: el.designation,
          data: el.magazine,
        }

        rr5.push(r5) // reasearch publications


        let r6 = {
          title: 'Popular Books',
          name: el.name,
          designation: el.designation,
          data: el.popular_book,
        }

        rr6.push(r6) // reasearch publications


        let r7 = {
          title: 'News Paper',
          name: el.name,
          designation: el.designation,
          data: el.news_paper,
        }

        rr7.push(r7) // reasearch publications


        let r8 = {
          title: 'E-Content',
          name: el.name,
          designation: el.designation,
          data: el.econtent,
        }

        rr8.push(r8) // reasearch publications

     


      })

      setResearch(rr1)
      setReasearchPaper(rr2)
      setTextBook(rr3)
      setOtherPublications(rr4)
      setMagazine(rr5)
      setPopularBook(rr6)
      setNewsPaper(rr7)
      setEContent(rr8)
      
    }
  }, [fData, isLoaded])

  return (
    <div>


      <div style={{marginTop: 40, fontSize: 22, fontWeight: 700}}>
        Research of {deptName}
      </div>

      
     


     
        <div>
          {Array.isArray(research) && research != null && research.length > 0 && (
            <div>
              <TitleStyle
                title={`Ongoing/Completed Research projects by faculties`}
              />
              {research != null &&
                research.map((el1, index1) => (
                  <div key={index1} style={{ marginBottom: 10 }}>
                    {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                      <table
                        width="100%"
                        className="table table-sm table-bordered"
                      >
                        <thead>
                          <tr>
                            <td colSpan="9">
                              {el1.name}, {el1.designation}
                            </td>
                          </tr>
                          <tr>
                            <td>#</td>
                            <td>Title of the project</td>
                            <td>Funding Agency</td>
                            <td>Duration of the Project</td>
                            <td>Project Cost (in Lakh)</td>
                            <td>Co-investigator (if any)</td>
                            <td>Status</td>
                            <td>Date of Commencement</td>
                            <td>Date of Completion </td>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                            el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.title}
                                <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                <td>{el.sponsored_by}</td>
                                <td>{el.duration}</td>
                                <td>{el.fund}</td>
                                <td>{el.co_researcher}</td>
                                <td>{el.status}</td>
                                <td>{el.commencement_date}</td>
                                <td>{el.submission_date} {el.quarter}</td>
                                {/* <td>{el.year}</td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>


    
        <div>
          {Array.isArray(reasearch_paper) &&
            reasearch_paper != null &&
            reasearch_paper.length > 0 && (
              <div>
                <TitleStyle title={`Research Publications by Faculties`} />
                {reasearch_paper != null &&
                  reasearch_paper.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                              <td>#</td>

                              <td>Title of the paper</td>

                              <td>Name of the Book/Journal</td>
                              <td>Published By</td>
                              <td>ISBN/ISSN</td>
                              <td>Volume</td>
                              <td>Page Nos</td>
                              <td>Date / Year</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>

                                  <td>{el.title_paper}
                                  <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                  <td>{el.title_book}</td>
                                  <td>{el.published_by}</td>
                                  <td>{el.isbn_no}</td>
                                  <td>{el.volume}</td>
                                  <td>{el.page_nos}</td>
                                  <td> {el.date_year}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>


    
        <div>
          {Array.isArray(text_book) &&
            text_book != null &&
            text_book.length > 0 && (
              <div>
                <TitleStyle title={`Chapter in Books by Faculties`} />
                {text_book != null &&
                  text_book.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                              <td>#</td>

                              <td>Name of the Book/Text Book</td>
                              <td>Published By</td>
                              <td>ISBN</td>
                              <td>Volume</td>
                              {/* <td>Page Nos</td> */}
                              <td>Edition</td>
                              <td>Name of authors</td>
                              <td>Year</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>

                                  <td>{el.title_book}
                                  <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                  <td>{el.published_by}</td>
                                  <td>{el.isbn_no}</td>
                                  <td>{el.volume}</td>
                                  {/* <td>{el.page_nos}</td> */}
                                  <td>{el.edition}</td>
                                  <td>{el.name_of_authors}</td>
                                  <td>{el.date_year}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>



        <div>
          {Array.isArray(otherPublications) &&
            otherPublications != null &&
            otherPublications.length > 0 && (
              <div>
                <TitleStyle title={`Other publications by faculties`} />
                {otherPublications != null &&
                  otherPublications.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                    <td>#</td>
                 

                 

                  <td>Title of the
                  Publication
                  </td>
                  <td>Published By</td>
                  <td>ISBN / ISSN</td>
                  <td>Volume</td>
                  <td>Page Nos</td>
                  <td>Year</td>
                  <td>Quarter</td>
                            <td>Any other
information</td>
                  
                </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                      <td>{index+1}</td>
                    
                  
                    <td>{el.title_book}
                    <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}>{el1.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a> : <a target="_blank" href={`${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a>}</div>)}</td>
                    <td>{el.published_by}</td>
                    <td>{el.isbn_no}</td>
                    <td>{el.volume}</td>
                    <td>{el.page_nos}</td>
                    <td> {el.date_year}</td>
                    <td> {el.quarter}</td>
                    <td>{el.other_info}</td>
                    
                  </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>


        <div>
          {Array.isArray(magazine) &&
            magazine != null &&
            magazine.length > 0 && (
              <div>
                <TitleStyle title={`Book/Journal/ Magazine Edited by faculties`} />
                {magazine != null &&
                  magazine.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                   

                 

                    <td>#</td>
                  

                 

                  <td>Title of the
Book/journal/Magazine</td>
                  <td>Published By</td>
                  <td>ISBN / ISSN</td>
                  <td>Year</td>
                  <td>Quarter</td>
                  <td>Any other Information</td>
                  
                </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                <td>{index+1}</td>
                             
                            
                              <td>{el.title_book}
                              <br />
                            {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}>{el1.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a> : <a target="_blank" href={`${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a>}</div>)}</td>
                              <td>{el.published_by}</td>
                              <td>{el.isbn_no}</td>
                              <td> {el.date_year}</td>
                              <td> {el.quarter}</td>
                              
                              <td>{el.other_info}</td>
          
                            </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>


        <div>
          {Array.isArray(popularBook) &&
            popularBook != null &&
            popularBook.length > 0 && (
              <div>
                <TitleStyle title={`Article in Popular Book/Magazine/Edited Book by faculties`} />
                {popularBook != null &&
                  popularBook.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                  
                 

                 

                    <td>#</td>
                  

                  <td>Title of the paper</td>

                  <td>Title of the Book/Magazine/Edited Book</td>
                  <td>Published By</td>
                  <td>ISBN</td>
                  <td>Volume</td>
                  <td>Page Nos</td>
                  <td>Year</td>
                  <td>Quarter</td>
                  
                </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                <td>{index+1}</td>
                              
                              <td>{el.title_paper}
                              <br />
                            {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}>{el1.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a> : <a target="_blank" href={`${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a>}</div>)}</td>
                              <td>{el.title_book}</td>
                              <td>{el.published_by}</td>
                              <td>{el.isbn_no}</td>
                              <td>{el.volume}</td>
                              <td>{el.page_nos}</td>
                              <td>{el.date_year}</td>
                              <td>{el.quarter}</td>
                            </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>


        <div>
          {Array.isArray(new_paper) &&
            new_paper != null &&
            new_paper.length > 0 && (
              <div>
                <TitleStyle title={`Publication in News Paper by faculties`} />
                {new_paper != null &&
                  new_paper.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                   
                 

                 

                    <td>#</td>
                  <td>Date / Year</td>

                  <td>Title of the Article</td>

                  <td>Name of the News Paper</td>
                  <td>Year/Quarter</td>
                  
                </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                <td>{index+1}</td>
                              <td> {el.date_year}</td>
                              <td>{el.title_paper}
                              <br />
                            {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}>{el1.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a> : <a target="_blank" href={`${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a>}</div>)}</td>
                              <td>{el.title_book}</td>
                              <td>{el.quarter}</td>
                              
          
                            </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>


        <div>
          {Array.isArray(eContent) &&
            eContent != null &&
            eContent.length > 0 && (
              <div>
                <TitleStyle title={`E-CONTENT DEVELOPMENT by faculties`} />
                {eContent != null &&
                  eContent.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                   
                 

                 

                            <td>#</td>
                  <td>Year</td>

                 

                  <td>Type of
E-content</td>
<td>Title of the Publication</td>
                  <td>Published By</td>
                  
                            <td>Remarks</td>
                            <td>Year/Quarter</td>
                  
                </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                      <td>{index+1}</td>
                    <td> {el.date_year}</td>
                  
                    <td>{el.title_paper}
                    <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}>{el1.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a> : <a target="_blank" href={`${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a>}</div>)}
                  </td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.other_info}</td>
                    <td>{el.quarter}</td>

                  </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>


     
        {/* <div>
          {Array.isArray(awards) && awards != null && awards.length > 0 && (
            <div>
              <TitleStyle title={`Awards/Recognitions received by faculties`} />
              {awards != null &&
                awards.map((el1, index1) => (
                  <div key={index1} style={{ marginBottom: 10 }}>
                    {el1.data != null && el1.data.length > 0 && (
                      <table
                        width="100%"
                        className="table table-sm table-bordered"
                      >
                        <thead>
                          <tr>
                            <td colSpan="9">
                              {el1.name}, {el1.designation}
                            </td>
                          </tr>
                          <tr>
                            <td>#</td>
                            <td>Name of the Award</td>
                            <td>Given By</td>
                            <td>Year</td>
                            <td>Other Information</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                            el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td> {el.area}
                                <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                <td>{el.achievement}</td>
                                <td>{el.award}</td>
                                <td>{el.other_info}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>




        <div>
          {Array.isArray(workshop) &&
            workshop != null &&
            workshop.length > 0 && (
              <div>
                <TitleStyle title={`Orientation programme/Refresher Course/Short term course by Faculties`} />
                {workshop != null &&
                  workshop.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                            <td>#</td>
              <td>Date & Year</td>
              <td>Organized by</td>
              <td>Title</td>
              <td>Duration</td>

              <td>Sponsored by</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                 <td>{index+1}</td>
                <td>{el.date_year}
                </td>
                <td>{el.organised_by}</td>
                <td>{el.theme}
                <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}
                  </td>
                <td>{el.duration}</td>
                <td>{el.sponsored_by}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div> */}

    </div>
  )
}
