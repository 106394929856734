import React, { Component } from "react";
import {
  Typography,
} from "@material-ui/core";
import PrincipalContainer from "./PrincipalContainer";
import GoverningBody from "./GoverningBody";

import { MDBContainer} from 'mdbreact';
import { GetData, PostData } from "../../api/service";

class AdministrationDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "principal" ? <PrincipalContainer /> : null}
        {this.props.type == "gb" ? <GoverningBody  /> : null}
        {this.props.type == "officestaff" ? <OfficeStaff  /> : null}
      </div>
    );
  }
}

export default AdministrationDescription;

export const OfficeStaff = () => {

  const [state,setState] = React.useState({
    data: [],
    isDataLoaded: false
  });

  function __getDeptData () {
    let d = {
      dept_code: 'TNC',
      type: 'OFFICE_STAFF'
  }

  PostData(`/getemployeebytype`, d)
      .then((resp) => {
        setState((prevState)=>{
          return {
            ...prevState,
            data:resp,
            isDataLoaded:true
          }
        })
      })
    
}

React.useEffect(()=>{
  __getDeptData();
},[])

  return(
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        Office Staff
      </Typography>


      <table
  className="MsoTableGrid"
  border={0}
  cellSpacing={0}
  cellPadding={0}
  style={{ borderCollapse: "collapse", border: "none" }}
>
  <tbody>
    <tr>
    <td
        width={111}
        valign="top"
        style={{
          width: "110.7pt",
          borderStyle: "none none solid",
          borderBottomWidth: "1pt",
          borderBottomColor: "windowtext",
          padding: "0in 5.4pt"
        }}
      >
        <p className="MsoNormal" style={{ lineHeight: "150%" }}>
          <b>
            <span
              style={{
                fontSize: "10.0pt",
                lineHeight: "150%",
                fontFamily: "Arial",
                msoBidiFontFamily: ""
              }}
              times=""
              new=""
            >
              &nbsp;
            </span>
          </b>
        </p>
      </td>
      <td
        width={140}
        valign="top"
        style={{
          width: "139.5pt",
          borderStyle: "none none solid",
          borderBottomWidth: "1pt",
          borderBottomColor: "windowtext",
          padding: "0in 5.4pt"
        }}
      >
        <p className="MsoNormal" style={{ lineHeight: "150%" }}>
          <b>
            <span
              style={{
                fontSize: "10.0pt",
                lineHeight: "150%",
                fontFamily: "Arial",
                msoBidiFontFamily: ""
              }}
              times=""
              new=""
            >
              NAME
              
            </span>
          </b>
        </p>
      </td>
      <td
        width={93}
        valign="top"
        style={{
          width: "92.7pt",
          borderStyle: "none none solid",
          borderBottomWidth: "1pt",
          borderBottomColor: "windowtext",
          padding: "0in 5.4pt"
        }}
      >
        <p className="MsoNormal" style={{ lineHeight: "150%" }}>
          <b>
            <span
              style={{
                fontSize: "10.0pt",
                lineHeight: "150%",
                fontFamily: "Arial",
                msoBidiFontFamily: ""
              }}
              times=""
              new=""
            >
              DESIGNATION
              
            </span>
          </b>
        </p>
      </td>

      <td
        width={93}
        valign="top"
        style={{
          width: "92.7pt",
          borderStyle: "none none solid",
          borderBottomWidth: "1pt",
          borderBottomColor: "windowtext",
          padding: "0in 5.4pt"
        }}
      >
        <p className="MsoNormal" style={{ lineHeight: "150%" }}>
          <b>
            <span
              style={{
                fontSize: "10.0pt",
                lineHeight: "150%",
                fontFamily: "Arial",
                msoBidiFontFamily: ""
              }}
              times=""
              new=""
            >
              Email & Phone
              
            </span>
          </b>
        </p>
      </td>
     
    </tr>
    {state.data.map((el)=>
    <tr>

<td
        width={100}
        valign="top"
        style={{ width: "99.9pt", border: "none", padding: "0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
           {Array.isArray(el.dp) && el.dp.map((el1) => <img
            src={el1} style={{width: 50}}
           />)}
            
          </span>
        </p>
      </td>
      
      <td
        width={140}
        valign="top"
        style={{ width: "139.5pt", border: "none", padding: "0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoFareastFontFamily: ""
            }}
            times=""
            new=""
          >
            {el.name}
          </span>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
            
          </span>
        </p>
      </td>
      <td
        width={93}
        valign="top"
        style={{ width: "92.7pt", border: "none", padding: "0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
           {el.designation}
            
          </span>
        </p>
      </td>

      <td
        width={93}
        valign="top"
        style={{ width: "92.7pt", border: "none", padding: "0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
           {el.email} <br />
           {el.phone}
            
          </span>
        </p>
      </td>


     
      
    </tr>
    )}
  </tbody>
</table>
</MDBContainer>

    </div>
  )
}
