import React from "react";
import { CardActionArea, Grid, Typography } from "@material-ui/core";
import SubscriptionModal from "../Essentials/SubscriptionModal";

export default function PanduFooter() {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  return (
    <div>
       {/* <SubscriptionModal isModalVisible={isModalVisible}  setIsModalVisible={setIsModalVisible}/> */}
      <div className="footer p-4">
        {/* <MDBContainer> */}
        <div className="row">
          <div
            className="col-md-3 d-flex"
            style={{alignItems: "center" }}
          >
            <img
              src={
                "/images/tnclogo.png"
              }
              alt="logo"
              style={{ width: "100%",padding:'0' }}
              className="col-3"
            />
            <div
              className="navbar-div-text col-9"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
                borderRight: "3px solid #251d25",
              }}
            >
              <Typography className="foot1h" variant="h5">
                THONG NOKBE COLLEGE
              </Typography>
              {/* <Typography className="foot1h" variant="h5">
                পাণ্ডু মহাবিদ্যালয়{" "}
              </Typography> */}
              <img src="/images/tnctext.png" style={{maxWidth:'11em',background:'#EAF3EB00',filter: 'invert(1)'}} alt="Thong nokbe College"/>
            </div>
          </div>

          <div className="col-md-2 mt-4 add">
            <div className="h6">Thong Nokbe College</div>
            <p>Karbi Anglong -782441<br/>Diphu, Assam, India.</p>
            <p>+91 6026020188</p>
            <p>principaltnc@gmail.com</p>
          </div>

          <div className="col-md-4 mt-4 add">
            <div className="h6">Important Links</div>
            <p>
              <a target="_blank" href="https://www.education.gov.in/en">
                {" "}
                Ministry of Education{" "}
              </a>{" "}
              |
              <a target="_blank" href="https://www.ugc.ac.in/">
                {" "}
                University Grants Commission{" "}
              </a>{" "}
              |
              <a target="_blank" href="https://nad.gov.in/">
                {" "}
                National Academic Depository{" "}
              </a>{" "}
              |
              <a target="_blank" href="https://dst.gov.in/">
                {" "}
                Ministry Of Science And Technology{" "}
              </a>{" "}
              |
              <a target="_blank" href="https://swayam.gov.in/">
                {" "}
                Swayam{" "}
              </a>{" "}
              |
              <a target="_blank" href="https://antiragging.in/">
                {" "}
                antiragging.in{" "}
              </a>{" "}
              |
              <a target="_blank" href="https://karbianglong.co.in/kaac//">
                {" "}
                karbianglong.co.in{" "}
              </a>{" "}
              |
            </p>
          </div>

          <div className="col-md-3 mt-4">
            <div className="form-group">
              <div className="h6">Subcsribe to out notifications</div>
              {/* <input className="form-control" type="text" name="" />
              <br /> */}
              <button className="btn btn-sm btn-success ml-auto" onClick={() => setIsModalVisible(true)}>Subscribe Now</button>
            </div>
          </div>
        </div>
        {/* </MDBContainer> */}
      </div>

      <div
        className="row px-4 py-2 bg-violet d-flex"
        style={{
          background: "#000",
          justifyContent: "space-between",
          margin: "0",
        }}
      >
        <div className="text-white font-weight-light">©2022 Thong Nokbe College</div>
        <div className="text-white font-weight-light">
          Designed & Developed With &#x2764; by Corexx
        </div>
      </div>
    </div>
  );
}
