import React, { Component } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { MDBContainer } from 'mdbreact';
import BreadCrumbOwn from '../component/Essentials/BreadCrumbOwn';

class OrganogramContainer extends Component {
    render() {
        return (
            <div>
                <BreadCrumbOwn title="Home > Organo" />
                <MDBContainer style={{ paddingTop: '40px', minHeight: '700px' }}>
                    <div className="d-flex justify-content-center">
                        <img src="/images/organo.jpeg" style={{ height: '100%', width: '20%', margin: '10px' }} />
                    </div>
                    <br />
                    <br />
                    <Typography variant="body1" gutterBottom style={{ textAlign: 'center', fontWeight: 500 }}>
                        <i>The organogram is an administrative diagram of College describes the decentralized structure of administration.
                            <br />
                            College administration is a cooperative effort of Principal, teaching, non-teaching staff and students with the cooperation and support of all stakeholders in pursuit of common objective. It is necessary that all aspects should be organized in order to attain the desired goals</i>
                    </Typography>


                </MDBContainer>
            </div>
        );
    }
}

export default OrganogramContainer;