import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import IqacDescription from "./IqacDescription";
import BreadCumb from "../Department/BreadCumb";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

const list = [
  {
    title: "Strategies",
    link: "/iqac/strategies",
  },
  {
    title: "Functions",
    link: "/iqac/functions",
  },
  {
    title: "IQAC Teams",
    link: "/iqac/teams",
  },
  {
    title: "IQAC Self Study Reports",
    link: "/iqac/ssr",
  },
  {
    title: "NAAC Matrices",
    link: "/iqac/matrix",
  },
  {
    title: "AQAR",
    link: "/iqac/aqar",
  },
  {
    title: "COs & POs",
    link: "/iqac/co_po",
  },
  {
    title: "Meeting Minutes",
    link: "/iqac/minutes",
  },
  {
    title: "Action Taken Report",
    link: "/iqac/atr",
  },
  // {
  //   title: "Meeting Minutes",
  //   link: "/iqac/minutes",
  // },
  {
    title: "Feedback Forms",
    link: "/iqac/feedback",
  },
  {
    title: "Feedback Analysis",
    link: "/iqac/feedback-analysis",
  },
  {
    title: "Student Satisfactory Survey",
    link: "/iqac/sss",
  },
  {
    title: "Event / News",
    link: "/iqac/event-news",
  },
  {
    title: "Best Practice",
    link: "/iqac/best-practice",
  },
  {
    title: "Institutional Distingtiveness",
    link: "/iqac/institutional-distingtiveness",
  },
  {
    title: "Annual Report",
    link: "/iqac/annual-report",
  },
  {
    title: "Academic Calendar",
    link: "/iqac/academic-calendar",
  },
  {
    title: "Organogram",
    link: "/iqac/organogram",
  },
];


class IqacComponent extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  render() {
    return (
      <div>
         <BreadCrumbOwn title="Home > IQAC" />
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          border: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: el.link === `/iqac/${this.state.link}` ? '#F5EB3D' :'#f9f9f9'
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "700",
                            padding: "0",
                          }}
                        >
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <IqacDescription type={this.state.link} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default IqacComponent;
