import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import StudentDescription from "./StudentDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

const list = [
  {
    title: "Notifications",
    link: "/students/notification",
  },
  {
    title: "Downloads",
    link: "/students/downloads",
  },
  {
    title: "Class Routines",
    link: "/students/routines",
  },
  {
    title: "Results",
    link: "/students/results",
  },
  {
    title: "Exam Schedule",
    link: "/students/exam-schedule",
  },
  {
    title: "Student Clubs",
    link: "/students/clubs",
  },
  {
    title: "Grievances",
    link: "/students/grievances",
  },
];

class PanduStudents extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  render() {
    return (
      <div>
         <BreadCrumbOwn title="Home > Student Notifications" />
        <Container className="mb-4">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          border: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: el.link === `/students/${this.state.link}` ? '#F5EB3D' :'#f9f9f9'
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "700",
                            padding: "0",
                          }}
                        >
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <StudentDescription type={this.state.link} />
            </Grid> 
        
          </Grid>
        </Container>
      </div>
    );
  }
}

export default PanduStudents;
