import { Typography } from '@material-ui/core';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import React, { PureComponent } from 'react'

class CommitteeDescription extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoaded: false
        }
    }


    __loadFile = () => {

        this.setState({
            data: this.props.data,
            isLoaded: true,
        }, () => {
            //console.log(this.state);
        })
    }

    componentDidMount() {
        this.__loadFile();
    }

    // componentDidMount() {
    //     this.setState({
    //         data: this.props.data,
    //         isLoaded: true,
    //     })
    // }

    render() {

        return (<>
            {this.state.isLoaded ? <div>
                <h4>{this.state.data.name}</h4>

                <h5 style={{ fontWeight: 500 }}>Members of {this.state.data.name}</h5>
                <ul>
                    {this.state.data.members.map((el) => (
                        <li><b>{el.member_name}</b>, {el.member_designation}</li>
                    ))
                    }
                </ul>
                <br />

                {this.state.data.objectives != null && <>

                    <div dangerouslySetInnerHTML={{ __html: this.state.data.objectives }}></div>

                    </>}

                {this.state.data.tasks != null && <>

                    <div dangerouslySetInnerHTML={{ __html: this.state.data.tasks }}></div>


                    <br /></>}
                {this.state.data.minutes != null && <><h5 style={{ fontWeight: 500 }}>Minutes of the meetings of {this.state.data.name}</h5>

                    <ul>
                        {Array.isArray(this.state.data.minutes) && this.state.data.minutes.map((el,index) => 
                        <li key={index}><img src={el.fileType == "PDF" ? "/images/icons/pdf-icon.png" : "/images/icons/image-icon.png"} style={{width: 20, marginRight: 10}} /><a target={"_blank"} href={el.url}>{el.path}</a></li>)}
                    </ul>
                    <br /></>}

            </div> : ''
            }</>
        )
    }
}

export default CommitteeDescription


// <h1>{this.props.data.name}</h1>
//             <br/>
//             <h4>Members of {this.props.data.name}</h4>
//             <ul>
//                 {this.props.data.members.map((el) => (
//                     <li>{el.member_name}</li>
//                 ))
//                 }
//             </ul>


