import { Avatar, Button, List } from 'antd';
import React, { Component } from 'react';
import { GetData } from '../api/service';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BreadCrumbOwn from '../component/Essentials/BreadCrumbOwn';
import {Container} from '@mui/material'

class Importantfiles extends Component {

    state = {
        data: [],
        isDataLoaded: false,

    }


    __getDeptData = () => {
        GetData(`/getimportantfiles`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isDataLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__getDeptData();

    }

    render() {
        return (
            <div>
                <BreadCrumbOwn title="Home > Important Information" />
                <Container style={{ minHeight: '80vh', marginTop: 40 }}>
                    <List
                        header={<div>IMPORTANT INFORMATION</div>}
                        bordered={true}
                        itemLayout="horizontal"
                        dataSource={this.state.data}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={<h3>{item.title}</h3>}

                                    description={<div style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item.description }} ></div>
                                    }
                                />

                                &nbsp;
                                &nbsp;
                                &nbsp;
                                {item?.upload_info?.map((el, index) =>
                                    <Button size="small" onClick={() => window.open(`${el.url}}`)}>File {++index}</Button>
                                )}
                            </List.Item>
                        )}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                </Container>
            </div>
        );
    }
}

export default Importantfiles;