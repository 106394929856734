import React, { Component, useState, useEffect } from 'react'

import { MDBContainer } from 'mdbreact';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Typography } from '@material-ui/core';
import BreadCumb from '../Department/BreadCumb';
import { GetData } from '../../api/service';


export default class GoverningBody extends Component {
    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/getgbmembers`).then((resp) => {
            this.setState({
                data: resp,
                isLoaded: true
            })
        })
    }


    render() {
        return (
            <div>



                <MDBContainer style={{ paddingTop: '20px' }}>

                    <br />


                    <div className="new-principal-div">
                        <h5 style={{ textAlign: 'center', color: '#1E3760', fontSize: '1.5em', marginTop: '1vh', fontWeight: 700 }}>Governing Body</h5>
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            The present Governing Body of the College is Consist of the following Members:
                        </Typography>

                        <br />

                        <div>





                            {this.state.isLoaded && <>

                                {
                                    this.state.data.map((el) =>
                                        <>
                                            <Card

                                            >
                                                <CardHeader>
                                                    <h5 style={{ color: 'inherit' }}>{el.type}</h5>
                                                </CardHeader>
                                                <CardBody>

                                                    {el.name} <br />
                                                    Contact : {el.phone}, {el.email}

                                                </CardBody>

                                            </Card>
                                            <br />
                                        </>
                                    )
                                }

                            </>}

                        </div>

                    </div>







                </MDBContainer>

            </div>
        )
    }
}